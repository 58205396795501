import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import title_image from '../../images/titles/people_and_change_conuslting.jpg'


const pageTitle = "People and Change Consulting";
const pageSlug = "people-and-change-consulting";


class PeopleConsulting extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Together, we will inspire your company to lead and thrive in today's market.</h6>
        </div>
</section>

<section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    
                  <h3>Managing transition is a must. A successful business needs a change-focused framework.</h3>
                  <span className="break"></span>
                  <p>To make our clients market leaders in their field, we help organizations do their best. Our experts assist you in building your base while directing your workers to the necessary change. We're innovative, tactical, agile, scalable. We are partnering with you to create a positive effect in your business.</p>
                  <p>Corporate flexibility is required to operate a business in today's market. In order to respond effectively and efficiently, agility requires dedicated leaders, inspiring vision, employee experience, and know how. To inspire their employees to make the right change, a good leader must have the right mindset.</p>
                  <h4>People and Development Consulting Services 
                  </h4>
                  <p>Let us assist your organization with changing the mindset of your employees:</p>
                  <h5>Organizational Change Management </h5>
                  <p>Let us develop your organization's Managerial, Community and Team Effectiveness</p>
                  <h5>Agile and Streamlined Change Strategy </h5>
                  <p>Shaping your community, to create highly effective teams and successful leaders</p>
                  <h5>Managing Business Change </h5>
                  <p>Develop technologies to help your company</p>

                  <span className="break"></span>
                  <h4>Why partner with us?</h4>
                  <p>There's no one way way to create a plan. We understand this and develop a plan for transformation that suits the corporate culture and blends with your strategic goals for the long term. Every company has its own needs for transformation. A customized and competent approach is assured to you, by collaborating with us.</p>
                  <p>In order to personalize a solution for you, the people side of change requires solutions that incorporate all applicable processes and technologies.</p>
                  <h5>Success Metrics </h5>
                  <p>Our experts ensure that your business needs are met through the development of adaptable success metrics. Enable your progress to be monitored.</p>
                  <h5>Specific Needs </h5>
                  <p>We create a solution that works for you based on the needs, budget and desired outcome of your company.</p>
                  <h5>Lasting Quality </h5>
                  <p>We coach the team in developing skills and competencies, providing you with a unique experience.</p>
                  <h5>Employee Mentoring</h5>
                  <p>Creating lasting change means changing the way the company approaches its members and workers. Encouraging people to think and act differently is vital, speaking and implementing new strategies, being inclusive is one of the most important aspects.</p>
                  <span className="break"></span>
                  <span className="break"></span>
                  <h4>Transformation Methods of all forms </h4>
                  <p>For all the following change events, we offer solutions:</p>
                  <ul>
                    <li>Enterprise Resource Planning (ERPs)</li>
                    <li>New technology products</li>
                    <li>Digital</li>
                    <li>Customer Relationship Management (CRMs)</li>
                    <li>Mergers and acquisitions</li>
                  </ul>



                    
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default PeopleConsulting
